@keyframes typing {
    0% { width: 0; }
    50% { width: 100%; }
    75% { width: 100%; }
    100% { width: 0; }
}

@keyframes wipe {
    0% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}